.sliderContent{
    width: 100%;
    height: 70vh;
}

.schemeHeading{
    border-left: 0.7em solid var(--prime-color);
}

.prime-color{
    color: var(--prime-color);
}