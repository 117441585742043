.category-card
{
    position: relative;
}
.card-overlay
{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    cursor: pointer;
    opacity: 0;
    transform: rotate(-360deg) scale(0);
    transition: all 0.7s ease;
}
.category-card:hover>.card-overlay
{
    transform: rotate(0deg) scale(1);
    opacity: 1;
}
.card-title
{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}