.loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.animate{
    position: relative;
    animation: flip 2s ease infinite alternate;
    animation-delay: calc(0.2s*var(--animation-delay));
    font-size: 1em;
}

@keyframes flip {
    0% {
        color: var(--prime-color);
        font-size: 1em;
    }
    80% {
        color: var(--border-color);
        font-size: 2em;
    }
}