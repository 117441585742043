.form-control
{
    color: var(--form-text);
}
.submit-btn
{
    background-color: var(--prime-color);
    font-size: 20px;
    padding: 14px 0px;
    transition: all 0.7s ease;
    border: 0px;
}
.submit-btn:hover
{
    background-color: var(--submit-btn-hover-color) !important;
}
.content
{
    height: 70vh;
}
.heading
{
    position: relative;
    display:flex;
    align-items: center;
}
.heading::before,
.heading::after
{
    content: " ";
    border-top: 2px solid var(--header-style-color);
    width: 100%;
}
.about
{
    margin-top: 20vh;
    height: 100%;
    overflow: hidden;
    background-color: var(--about-bg-color);
}
.title
{
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
    color:var(--about-text-color);
    font-size: 40px;
    text-shadow: 0 1px 2px rgb(0 0 0 / 18%);;
}
.about p
{
    color: var(--about-info-color);
}
.read-more
{
    border: 2px solid var(--border-color);
    padding: 10px 40px;
    transition: all 0.2s ease;
    text-decoration: none;
    font-family: inherit;
    color:var(--about-text-color);
    position: relative;
    background:transparent;
    letter-spacing: 2px;
    z-index: 1;
    overflow: hidden;
}
.read-more-transition
{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0%;
    transform:scale(0,0.7);
    z-index: -1;
    top: 0;
    transform-origin: 50% 50%;
    background: repeating-linear-gradient(90deg,#bcfff7cb 3%,var(--about-bg-color) 3%,var(--about-bg-color) 6%,#bcfff7cb 6%,#bcfff7cb 9%);
    transition: all 0.4s ease;
}
.read-more-transition::before
{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    z-index: -1;
    top: 0;
    background: repeating-linear-gradient(90deg,#bcfff7cb 2%,var(--about-bg-color) 2%,var(--about-bg-color) 4%,#bcfff7cb 4%,#bcfff7cb 6%);
    transition: all 0.4s ease;
    transition-delay: 0.1s;
    
}
.read-more:hover
{
    color: var(--about-text-color);
}
.read-more:hover>.read-more-transition
{
    width: 100%;
    height: 100%;
    transform:scale(1,1);
    
}
.read-more:hover>.read-more-transition::before
{
    left: 0%;
}