.active
{
    color: var(--prime-color) !important;
}
.nav-item
{
    margin-left:10px;
}
.nav-link
{
    color: var(--nav-link-color) !important;
    font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    letter-spacing: 2px;
}
.nav-link:hover
{
    color:var(--prime-color) !important;
    transition: 0.25s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.dropdown-menu
{
    display: block;
    height: 0px !important;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    border:0px;
    transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
}
.dropdown-link:hover .dropdown-menu,
.dropdown-link:active .dropdown-menu
{
    height: auto !important;
}
.dropdown-menu li a:hover
{
    transition: 0.2s ease-in;
}
.header
{
    height: 15vh;
}
.navbar
{
    height: inherit;   
}
.logo
{
    min-width: 10vh;
    max-width: 11vh;
}
.header-content
{
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    font-size: 2.5em;
    display: inline-block;
}
.header-content-small
{
    color: var(--text-color-white);
    letter-spacing: 9px;
}
.userName
{
    color: var(--prime-color);
}
.profileMenu
{
    right:0%;
    top : 110%
}

.navbarBg {
    background-color: var(--bg-blur-color);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}