.table-outer{
    width: 100%;
}

.inquiry-table{
    height: 90vh !important ;
    overflow-y: auto;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}