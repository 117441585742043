.bg-img-login
{
    background: url('../../../public/images/banner.jpg') no-repeat fixed center;
    background-position-y: top;
    background-size: cover;
    height: 100vh;
}

.loginBox{
    width: 35vw;
    background-color: var(--bg-login-blur-color);
    backdrop-filter: blur(5px);
}

.loginHeader{
    border-left: 0.2em solid var(--prime-color);
    margin: 0.5em;
    padding: 0.4em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2rem;

}

.submit-btn
{
    background-color: var(--prime-color);
    font-size: 20px;
    padding: 14px 0px;
    transition: all 0.7s ease;
    border: 0px;
}
.submit-btn:hover
{
    background-color: var(--submit-btn-hover-color) !important;
}

@media screen and (max-width:900px){
    .loginBox{
        width: 70vw;
    }
}