.search {
    padding: 0.3em 1em;
    font-size: 1.2em;
}

.search:focus{
    outline: none;
    box-shadow: none;
    border-color: var(--prime-color);
}

.searchBtn{
    background: var(--prime-color);
    padding: 0.3em 0.2em;
    font-size: 1.4em;
    transition : all 0.4s ease;
}

.searchBtn:hover {
    color: #fff;
    background: var(--submit-btn-hover-color);
}

.tiny-filters
{
    border-radius: 10px;
    position: absolute;
    background-color: #fff;
    height: auto;
    width:40vw;
    margin: 0;
right: 10%;
top:110%;
    z-index: 1 !important;
}

@media screen and (max-width: 600px){
    .tiny-filters
{
    border-radius: 10px;
    position: absolute;
    background-color: #fff;
    height: auto;
    width:70vw;
    margin: 0;
right: 10%;
top:110%;
    z-index: 1 !important;
}
}