.propertyCard {
    margin: 2em;
    width: 18rem;
}

.btnLink {
    background-color: var(--prime-color);
}

.btnLink:hover {
    background-color: var(--submit-btn-hover-color);
    color: var(--text-color-white);
}