.city{
    height: 100vh;
}

.cityCard{
    height: 70vh;
    width: 50vw;
}

.list-group-item{
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
}

.addBtn{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}
