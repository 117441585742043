.profileName{
    color: var(--prime-color);
}

.form-floating{
    border-color: #444;    
}

.form-control:focus{
    border-color: var(--prime-color) !important;
    box-shadow:none !important;
    outline: none !important;
}

.submitBtn{
    background-color: var(--prime-color);
    color: var(--text-color-white);
}

.submitBtn:hover{
    background-color: var(--submit-btn-hover-color);
    color: var(--text-color-white);
}