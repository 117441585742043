.bg-img
{
background: url('../../../public/images/banner.jpg') no-repeat fixed center;
background-position-y: top;
background-size: cover;
}

.about {
    margin-top: 2vh;
    min-height: 35vh;
    overflow: hidden;
    background-color: var(--about-bg-color);
}