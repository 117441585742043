.specializedHeader{
    border-left: 0.2em solid var(--prime-color);
    padding: 0em 0.4em;
    margin: 0.2em;
    word-spacing: 0.2em;
    font-size: 3em;
}


.highlight{
    color: var(--prime-color)
}

.specializedService p{
    color: var(--about-info-color);
}